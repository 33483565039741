import React, { useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import _ from 'lodash'

import { useDispatch } from "react-redux";
import { actionLogin } from '../../../../redux/auth/actions'
import { Input, Form } from 'antd';
import Button from '../../../../components/CustomButton'
import { validateEmailWithoutSpecial } from "../../../lib/common"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const formRef = React.createRef()

function Login(props) {

  const { history } = props;
  // const history = useHistory()
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onEnterPress = async (e) => {
    if (e.key === "Enter") {
      await onSubmitDelayed()
    }
  }

  const onSubmitDelayed = _.debounce(() => onSubmit(), 100)
  const onSubmit = async () => {
    await formRef.current.validateFields();
    setLoading(true)
    const { email, password } = formRef.current.getFieldsValue()

    const dataSubmit = {
      email: email.trim(),
      password: password.trim()
    }

    const res = await dispatch(actionLogin(dataSubmit))
    console.log('KKKKKKKKK', res)
    if (res) {
      console.log('Nhảy ')
      window.location.assign("/dashboard")
    }

    setLoading(false)
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1" style={{ color: '#D6B970' }}>
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Nhập email và mật khẩu
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <Form
        name="customForm"
        ref={formRef}
        className="form fv-plugins-bootstrap fv-plugins-framework"
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={onSubmit}
      >
        {/* <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
          <div className="alert-text row-center fs--16 lh--24">
            <strong>Chào mừng trở lại 100MAN!</strong>
          </div>
        </div> */}

        <div className="form-group fv-plugins-icon-container">
          <Form.Item
            type="text"
            name="email"
            rules={[
              {
                validator: (_, value) => {
                  if (!value || value.trim() === "") {
                    return Promise.reject("Mời bạn nhập Email!")
                  } else if (value && !validateEmailWithoutSpecial(value.trim())) {
                    return Promise.reject("Email không đúng định dạng!")
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            ]}
          >
            <Input
              className="customInput"
              placeholder="Email"
              // prefix={<img src="images/email_24px.svg" alt="" />}
              onKeyPress={e => onEnterPress(e)}
              style={{ background: '#282828', color: '#b8bbbb' }}
            />
          </Form.Item>
        </div>
        <div className="form-group fv-plugins-icon-container mt--13">
          <Form.Item
            name="password"
            rules={[
              {
                validator: (_, value) => {
                  if (!value || value.trim() === "") {
                    return Promise.reject("Mời bạn nhập Password!")
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            ]}
          >
            <Input
              type="password"
              className="customInput"
              placeholder="Password"
              // prefix={<img src="images/lock_24px 2.svg" alt="" />}
              onKeyPress={e => onEnterPress(e)}
              style={{ background: '#282828', color: '#b8bbbb' }}
            />
          </Form.Item>
        </div>
        <div className="form-group justify-content-between align-items-center" style={{ textAlign: 'center', marginTop: 20 }}>
          <Button
            type='submit'
            className="width100"
            loading={loading}
            title="Đăng nhập"
            btnColor="#ffffff"
            btnBgColor="#c96f00"
            btnHoverBackground="#df800c"
            onClick={onSubmitDelayed}
            style={{ border: 'none' }}
          />
        </div>
      </Form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
