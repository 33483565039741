import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import authReducer from "./auth/reducer"
import homeReducer from "./home/reducer"
import historyAssetReducer from "./history_asset/reducer";
import userClientReducer from "./userClient/reducer"
import roleAndPermissionReducer from "./roleAndPermission/reducer"
import userManagementReducer from "./user_management/reducer"

import postReducer from "./post/reducer"
import projectReducer from "./project/reducer"
import jobReducer from "./job/reducer"
import requestReducer from "./request/reducer"
import serviceReducer from "./service/reducer"
import imageReducer from "./image/reducer"
import memberReducer from "./member/reducer"
import teamReducer from "./team/reducer";
import dailyCodeReducer from "./daily_code/reducer";



export const rootReducer = combineReducers({
  auth: auth.reducer,
  authReducer: authReducer,
  homeReducer: homeReducer,
  requestReducer: requestReducer,
  userClientReducer,
  historyAssetReducer,
  roleAndPermissionReducer,
  userManagementReducer,
  postReducer,
  projectReducer,
  jobReducer,
  serviceReducer,
  imageReducer,
  memberReducer,
  teamReducer,
  dailyCodeReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
